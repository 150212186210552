<template>
	<el-form :model="form" label-width="105px" class="form">
		<div class="subTit"><i class="el-icon-caret-bottom" />联系我们</div>
		<div class="center">
			<el-form-item label="关键词:">
				<el-input v-model="form.keywords" />
			</el-form-item>
			<el-form-item label="描述:">
				<el-input type="textarea" :rows="3" v-model="form.description" />
			</el-form-item>
			<el-form-item label="联系人姓名">
				<el-input v-model="form.cperson" />
			</el-form-item>
			<el-form-item label="联系人手机号">
				<el-input v-model="form.cphone" />
			</el-form-item>
			<el-form-item label="联系人座机">
				<el-input v-model="form.ctel" />
			</el-form-item>
			<el-form-item label="内容">
				<tinyMce :content="form.content" v-model="form.content" :change.sync="form.content"  />
				<!-- <vue-tinymce v-model="form.content" :setting="setting" :setup="setup" /> -->
			</el-form-item>
		</div>
		<div class="submit-btn" @click="handleSubmit">保存</div>
	</el-form>
</template>

<script>
	import {
		shops,
		setup
	} from '@/assets/js/tinymceSt.js'
	import tinyMce from '@/components/plug/tinyMce_shop'
	export default {
		data() {
			return {
				form: {
					keywords: '',
					description: '',
					cperson: '',
					cphone: '',
					ctel: '',
					content: ''
				},
				imageUrl: '',
				shops,
				setup
			}
		},
		created() {
			this.$axios.post('/getSellerLanmuContent', {
				lm_id: 2
			}).then(data => {
				for (let i in this.form) {
					if (data[i]) {
						this.form[i] = data[i]
					}
				}
			})
		},
		methods: {
			handleSubmit() {
				this.form.lm_id = 2
				this.form.seller_id = this.$store.state.SELLER_ID
				this.$axios.post('/setSellerLanmuContent', this.form).then(data => {
					if (data && data.code == 400005) {
						this.$message.error(data.msg)
						return
					}
					this.$message.success('设置成功')
				})
			}
		},
		components: {
			tinyMce
		}
	}
</script>

<style>
</style>
